import clsx from "clsx";
import React, { useRef } from "react";
import { useEffect } from "react";
import "./PilotButton.css";
import translations from "../lang/translations";



export type PilotButtonProps = {
  className?: string;
  children?: any;
};

export default function PilotButton({
  className,
}: PilotButtonProps) {
  const lsApiRef = useRef<any>(null)
  useEffect(()=>{
    const assignApi = async (event: any) => {
      console.log("LIVESUPP_LOADED");
      lsApiRef.current = event.detail.api; // we will refer to the PageAPI object as lsAPI
    }
    window.addEventListener("LIVESUPP_LOADED", assignApi);
    return ()=>{
      window.removeEventListener("LIVESUPP_LOADED", assignApi)
    }
  }, [])
  return (
    <button className={clsx("ButtonLink", className)} onClick={()=>{
      if (lsApiRef.current !== null) {
        lsApiRef.current.setActionMenuActive(true);
      } else {
        alert("LsApi not available")
      }
    }} >
      {translations.toplook_column_cta}
    </button>
  );
}
