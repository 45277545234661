import React from "react";
import "./References.css";
import BondsterIcon from "../public/images/section-images/references-allianz.png";
import NordicIcon from "../public/images/section-images/references-nordic.png";
import translations from "../lang/translations";


export type ReferencesProps = {};

/*
App > Routes/Index > References > Carousel
"Zavedení sdílení webových stránek s klienty nám přineslo zrychlení a zkvalitnění služeb pro naše klienty. Klient už nám nemusí dlouze popisovat, co má za problém, jednoduše se připojíme na jeho obrazovku, můžeme problém vyřešit a navést klienta s ukázkou funkcionality. Nové funkce si chválí i naši klienti."
"Při porovnání služby LiveSupp s našimi předchozími nástroji (chat a chatbot), překonaly výsledky všechna naše očekávání. Okamžitě se zvedl počet objednávek z našich webových stránek a LiveSupp se stal jedním z našich významných prodejních kanálů."
*/

export default function References() {
  return (
    <section className="references_func">
      <div className="references_main">
      <div className="references_div">
        <img src={BondsterIcon} alt="" />
        <div>
          <p>
            <i>{translations.references_1}</i>
          </p>
          <p>- Jiří Nový, Senior expert digital & e-commerce, Allianz</p>
        </div>
      </div>

      <div className="references_div">
        <img src={NordicIcon} alt="" />
        <div>
          <p>
          <i> 
            {translations.references_2}
          </i></p>
          <p>- Martina Čekalová, Sales Manager, Nordic Telecom s.r.o.</p>
        </div>
      </div>
      </div>
    </section>
  );
}
