import "./App.css";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Index from "./Routes/Index";
import React from "react";
import {IntlProvider} from 'react-intl';
import { messages } from "./lang/translations";

export default function App() {
    var language=navigator.language.split("-")[0];
  if (language === "cs"){ 
    if (window.sessionStorage.getItem("lang")===null) {
      window.sessionStorage.setItem("lang","cs");
      window.location.href = '/cs/'; 
    }
  } else {
    if (window.sessionStorage.getItem("lang")===null) {
    window.sessionStorage.setItem("lang","en")
    }
  };
  language = "en";

const langMessages = language in messages ? messages[language as keyof typeof messages] : messages.en
const locale = language in messages ? language : "en"  
return (
    <IntlProvider messages={langMessages} locale={locale} defaultLocale="en">
    <Router>
      <Route path="/" exact>
        <Index />
      </Route>
    </Router>
    </IntlProvider>
  );
}
