import React, { useCallback, useState } from "react";
import PercentageCircles from "../Circle/PercentageCircles";
import Box from "../Box/Box";
import Pricing from "../Pricing/Pricing";
import Carousel from "../Carousel/Carousel";
import ToplookImage from "../public/images/toplook-image.png";
import LockIcon from "../public/images/section-images/lock.svg";
import WebrtcIcon from "../public/images/section-images/webrtc.svg";
import CrmIcon from "../public/images/section-images/CRM.svg";
import CommPhone from "../public/images/section-images/communication_image.png";
import EffImage from "../public/images/effectivity-image.png";
import HappPhone from "../public/images/section-images/happiness-phone.png";
import StatImg from "../public/images/section-images/stat_design.png";
import BmLogo from "../public/logos/bm.svg";
import CLogo from "../public/logos/c.png";
import MeLogo from "../public/logos/me.png";
import NtLogo from "../public/logos/nt3.png";
import ZfpLogo from "../public/logos/zfp.png";
import CaLogo from "../public/logos/ca.png";
import CedLogo from "../public/logos/allianz-logo.svg";
import CpzLogo from "../public/logos/cpz.png";
import DeLogo from "../public/logos/de.png";
import AbLogo from "../public/logos/abet_logo.png";
import LaLogo from "../public/logos/labora_logo.png";
import ConversionUri from "../public/images/section-images/conversion.svg";
import PhoneUri from "../public/images/section-images/phone.svg";
import MessagingUri from "../public/images/section-images/messaging.svg";

import "./Index.css";
import "./Index_responsive.css";
import References from "../References/References";
import PilotButton from "../PilotButton/PilotButton";
import LinkButton, { LinkButtonTypeEnum }from "../LinkButton/LinkButton";
import translations from "../lang/translations";
import Header from "../Header/Header";


export default function Index() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <div className="app">
    <Header
      isMenuOpen={isMenuOpen}
      onMenuToggle={() => setIsMenuOpen((previousValue) => !previousValue)}
    />

    <main onClick={closeMenu}>
      <section className="toplook">
        <div className="toplook_corner">
          <h4>
            - Internet with human senses
          </h4>
        </div>
        <div className="toplook_column">
          <h1>
            {translations.toplook_column_heading}
          </h1>
          <p>
            {translations.toplook_column_body}
          </p>
          <PilotButton className="ButtonLink__primary_top">
            {translations.toplook_column_cta}
          </PilotButton>
        </div>
        <img src={ToplookImage} alt="ukazka UI na telefonu a pc" />
      </section>
      <section className="circles">
        <ul className="circlesUL">
          <li>
            <PercentageCircles percentage={200} color="#40b1ba">2x</PercentageCircles> 
            {translations.percentagecircle1}
          </li>
          <li>
            <PercentageCircles percentage={300} delay={200} color="#40b1ba">3x</PercentageCircles>{" "}
            {translations.percentagecircle2}
          </li>
          <li>
            <PercentageCircles percentage={140} delay={400} color="#40b1ba">40%</PercentageCircles>{" "}
            {translations.percentagecircle3}
          </li>
          <li>
            <PercentageCircles percentage={160} delay={600} color="#40b1ba">60%</PercentageCircles>{" "}
            {translations.percentagecircle4}
          </li>
          <li>
            <PercentageCircles percentage={190} delay={800} color="#40b1ba">90%</PercentageCircles>{" "}
            {translations.percentagecircle5}
          </li>
        </ul>
      </section>
      <Carousel
        className="Carousel_conversion"
        id="boxes"
        slides={[
          <Box
            text={translations.box_1}
            img={ConversionUri}
          />,
          <Box
            text={translations.box_2}
            img={PhoneUri}
          />,
          <Box
            text={translations.box_3}
            img={MessagingUri}
          />
        ]}
      />
      <section className="communication">
          <h1 className="communication_heading">{translations.communication_heading}
          <br /> 
          <p>
            {translations.communication_subheading}
          </p>
          </h1>
        <div className="communication_text">
          <ul className="communicationUL">
            <li>
            {translations.communication_li_1}
            </li>
            <li>
            {translations.communication_li_2}
            </li>
            <li>
            {translations.communication_li_3}
            </li>
            <li>
            {translations.communication_li_4}
            </li>
            <li>
            {translations.communication_li_5}
            </li>
          </ul>
        </div>
        <div className="Grid_cta_wrapper_communication">
        <LinkButton type={LinkButtonTypeEnum.Secondary} uri="features.html" className="GridButton communication_cta">
          {translations.communication_cta}
        </LinkButton>
        </div>
        <img
          className="communication_image"
          src={CommPhone}
          alt="Phone interface example"
        />
      </section>
      <h1 className="effectivityHeading">{translations.effectivity_heading}</h1>
      <section className="effectivity">
        <img
          src={EffImage}
          className="effectivity_img"
          alt="obrazek efektivita"
        />
        <Carousel
          className="Carousel_references"
          slides={[
            <div className="Carousel_references_slide effectivitySlide">
              {translations.effectivity_li_1}
            </div>,
            <div className="Carousel_references_slide effectivitySlide">
              {translations.effectivity_li_2}
            </div>,
            <div className="Carousel_references_slide effectivitySlide">
              {translations.effectivity_li_3}
            </div>
          ]}
        />
      </section>
      <section className="happiness">
          <h1 className="happiness_heading">{translations.happiness_heading}</h1>
        <div className="happiness_text">
          <ul className="happinessUL">
            <li>
            {translations.happiness_li_1}
            </li>
            <li>
            {translations.happiness_li_2}
            </li>
            <li>
            {translations.happiness_li_3}
            </li>
            <li>
            {translations.happiness_li_4}
            </li>
            <li>
            {translations.happiness_li_5}
            </li>
            <li>
            {translations.happiness_li_6}
            </li>
          </ul>
        </div>
        <div className="Grid_cta_wrapper">
        <LinkButton type={LinkButtonTypeEnum.Secondary} uri="use-case.html" className="GridButton happiness_cta">
          {translations.happiness_cta}
        </LinkButton>
        </div>
        <img
          className="happiness_image"
          src={HappPhone}
          alt="Phone interface example"
        />
      </section>
      <h1 className="statisticsHeading">{translations.statistics_heading}</h1>
      <section className="statistics">
        <img
          src={StatImg}
          className="stat_img"
          alt="obrazek statistiky"
        />
        <Carousel
          className="Carousel_references"
          slides={[
            <div className="Carousel_references_slide">
              {translations.statistics_li_1}
            </div>,
            <div className="Carousel_references_slide">
              {translations.statistics_li_2}
            </div>,
            <div className="Carousel_references_slide">
              {translations.statistics_li_3}
            </div>
          ]}
        />
      </section>
      <Carousel
        className="Carousel_conversion"
        id="boxes"
        slides={[
          <Box
            text={translations.box_4}
            img={LockIcon}
          />,
          <Box
            text={translations.box_5}
            img={WebrtcIcon}
          />,
          <Box
            text={translations.box_6}
            img={CrmIcon}
          />
        ]}
      />
      <section className="references">
        <h1 className="referencesHeading">{translations.references_heading}</h1>
        <div className="clients_items">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ppas.cz/podpis"
          >
            <img src={BmLogo} alt="PPAS" title="PPAS" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mutumutu.cz/kalkulacka/uvod"
          >
            <img src={CLogo} alt="Mutumutu" title="Mutumutu" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.energeticka-bilance.cz/dokumenty/maxim_energy.htm"
          >
            <img src={MeLogo} alt="Maxim Energy" title="Maxim Energy" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.nordictelecom.cz/"
          >
            <img src={NtLogo} alt="Nordic Telecom" title="Nordic Telecom" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.zfpa.cz/"
          >
            <img src={ZfpLogo} alt="ZFP Akademie" title="ZFP Akademie" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cyrrus.cz/"
          >
            <img src={CaLogo} alt="Cyrrus" title="Cyrrus" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.allianz.cz/cs_CZ/online-sjednani/povinne-ruceni-havarijni-pojisteni.html"
          >
            <img src={CedLogo} alt="Allianz" title="Allianz" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bezpersonalky.com/"
          >
            <img src={CpzLogo} alt="Bezpersonalky" title="Bezpersonalky" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.energeticka-bilance.cz/dokumenty/dobra_energie.html"
          >
            <img src={DeLogo} alt="Dobra energie" title="Dobra energie" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.laboraczech.cz/"
          >
            <img src={LaLogo} alt="Labora" title="Labora" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://abethandicap.cz/"
          >
            <img src={AbLogo} alt="Vsevid" title="Vsevid" />
          </a>
        </div>
      <References />
      </section>
      </main>
    <footer className="footer_tag">
      <div className="footer_container">
        <div><p className="footer_item">&copy; {(new Date()).getFullYear()} ATOM RTC s.r.o.</p></div>
        <div><p className="footer_item"><span className="footer_hidden">|</span>+420 778 95 66 00</p></div>
        <div>
          <p className="footer_item"><span className="footer_hidden">|</span><a href="mailto:info@livesupp.com">info@livesupp.com</a> </p>
        </div>
      </div>
    </footer>
  </div>

  );
}
